<template>
  <v-container>
    <!--BOC:[breadcrumb]-->
    <ABreadcrumb :items="breadcrumb.items" />
    <!--EOC-->
    <!--BOC:[title]-->
    <APageTitle title="Refresh"/>
    <!--EOC-->
    <p>Increase following number everytime when want to test if refresh is working.</p>
    <h5>Page Cache</h5>
    <p>9</p>
    <h5>Javascript Cache</h5>
    <p>Javascript Version {{javascriptFile[0].version}}</p>
    <h5>Component Cache</h5>
    <Refresh/>
    <TestRefresh/>
    <div><v-btn class="mb-1" @click="$router.push({name:'PageServiceTestingRefreshPage2'})">Go to Page 2</v-btn></div>
    <div><v-btn class="mb-1" @click="forceRefresh1">Method 1: Force Refresh - window.location.reload(true).</v-btn></div>
    <div><v-btn class="mb-1" @click="forceRefresh2">Method 2: Force Refresh - window.location.href</v-btn></div>
    <v-card>
      <v-card-title>Summary on Dec 20, 2023</v-card-title>
      <v-card-text>
        <h6>Observation</h6>
        <p>Navigating around without pressing refresh or re-enter the url will not be able to fetch latest updates no matter what. Refreshing already able to update most files.</p>
        <p>Edge - Refresh is based on entry URL: if enter directly to page A > refresh then it will work, however it will failed when enter directly to page B > go page A even if have refreshed at page A. This proves that Edge cache index.html and all related files upon entry.</p>
        <hr/>
        <h6>Set No Cache in Web Server</h6>
        <p><b>WORKED</b></p>
        <p>Apply server side no cache.</p>
        <p>Add following codes to nginx config file. This prevents index.html to be cached.</p>
        <p>location ~* \.(html)$ {
          add_header Cache-Control "no-store, no-cache, must-revalidate, max-age=0";
        }</p>
        <hr/>
        <h6>Force Refresh (Any Type)</h6>
        <p><b>PASSIVE</b></p>
        <p>It will work to refresh the page and get latest content.</p>
        <hr/>
        <h6>Increase App Version</h6>
        <p><b>Unknown</b></p>
        <p>This method has yet to be proven to be effective.</p>
        <hr/>
        <h6>Cache Busting (File Hashing)</h6>
        <p><b>FAILED</b></p>
        <p>Idea: Update vue.config.js to configure webpack on how files are named.</p>
        <p>Vue will handle file hashing during production when run "$ yarn build". By default it is using app.[contenthash].js method.</p>
        <p>Browser like Edge will cache the base file, index.html and all related js files.</p>
        <p>If a file is cached by browser, hashing files will not be able to fix it as it already been cached.</p>
        <hr/>
        <h6>Set No Cache in index.html</h6>
        <p><b>Unknown</b></p>
        <p>Apply meta cache control: <a href="https://stackoverflow.com/a/2068407" target="_blank">https://stackoverflow.com/a/2068407</a></p>
        <p>Some browsers are known to ignore meta cache control settings.</p>
        <hr/>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
/**
 * Method 0: Do nothing
 * Scenario (a): Opened the page before updating then navigate to other page and return to this page
 * ✘ Windows Chrome (Version 120.0.6099.110)
 * ✘ Windows Edge (Version 120.0.2210.77)
 * ✘ iOS Safari (iOS 14.8.1)
 * ✘ iOS Chrome (Version 120.0.6099.119)
 *
 * Scenario (b): Re-entering via visit crm.taxpod.my then navigate to this page
 * ✔ Windows Chrome (Version 120.0.6099.110)
 * ✔ Windows Edge (Version 120.0.2210.77)
 * ✔ iOS Safari (iOS 14.8.1)
 * ✘ iOS Chrome (Version 120.0.6099.119)
 *
 * Scenario (c): Press Ctrl + R
 * ✔ Windows Edge (Version 120.0.2210.77)
 *
 * Method 1
 * ✔ Windows Chrome (Version 120.0.6099.110)
 * ✔ Windows Edge (Version 120.0.2210.77)
 * ✔ iOS Safari (iOS 14.8.1)
 * ✔ iOS Chrome (Version 120.0.6099.119)
 */
//BOC:[breadcrumb]
import Breadcrumb from "@/objects/breadcrumb";
//EOC
//BOC:[components]
import Refresh from "@/services/testing/components/PageServiceTestingRefresh/Refresh";
//EOC
//BOC:[config]
import configRefresh from "@/services/testing/config/refresh";
//EOC
import { mapState } from "vuex";
export default {
  components: {
    Refresh,
  },
  computed: {
    ...mapState({
      //
    }),
  },
  props: {
    //
  },
  data: () => ({
    //BOC:[breadcrumb]
    breadcrumb: new Breadcrumb(),
    //EOC
    javascriptFile: configRefresh,
  }),
  watch: {
    //
  },
  created() {
    //BOC:[breadcrumb]
    this.breadcrumb.add({
      text: "Dashboard",
      to: { name: "PageConsoleDashboard" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Services",
      to: { name: "PageServiceBrowse" },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Testing",
      to: { name: "PageServiceDashboard", params: { serviceKey: "testing" } },
      exact: true,
    });
    this.breadcrumb.add({
      text: "Refresh",
      to: { name: "PageServiceTestingRefresh" },
      exact: true,
    });
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    forceRefresh1() {
      window.location.reload(true);
    },
    forceRefresh2() {
      window.location.href = window.location.href.concat("");
    },
  },
};
</script>